@use "../../scss/" as *;

.page-header {
    padding: 9rem 0 0rem;
    text-align: center;
   

    position: relative;


    h2 {
        position: relative;
        z-index: 99;
        font-size: 3rem;
        color: #00c3ff;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-image: linear-gradient(to top, $body-bg, rgba(black, 0));
    }
}