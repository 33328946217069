@use '../../scss/' as *;

button{
    cursor: pointer;
}

.btn {
    border: 4px solid transparent;
    background-color: #00c3ff;
    color: white;
    border-radius: $border-radius;
    padding: 0.5rem 1.8rem;
    font-size: 1.5rem;
    font-weight: 600;
    box-shadow: 0px 0px 7px 8px #00d9ff4d;
    transition: box-shadow 0.3s ease;
    position: relative;
    
}

.btn:hover {
    box-shadow: 0px 0px 7px 15px #05c5ff4d;
}

.btnoutline{
    
    border: 3px solid #fff;
    background-color: transparent;
    color: white;
    border-radius: $border-radius;
    padding: 0.5rem 1.8rem;
    font-size: 1.5rem;
    font-weight: 600;
    box-shadow: unset;
    transition: color 0.3s ease, background-color 0.3s ease;
    position: relative;

}
.btnoutline1{
    font-family: $font-family;
    border: 3px solid rgba(255, 255, 255, 0.5);
    background-color: transparent;
    color: white;
    border-radius: 15px 50px;
    padding: 0.5rem 1.8rem;
    font-size: 1.6rem;
    font-weight: 600;
    box-shadow: unset; 
    position: relative;
    
}

.btnoutline2{
    
    border: 2px solid #fff;
    background-color: transparent;
    color: white;
    border-radius: $border-radius;
    padding: 0.5rem 1.5rem;
    font-size: 1.2rem;
    font-weight: 600;
   
    transition: color 0.3s ease, background-color 0.3s ease;
    position: relative;
    
}

.btnoutline2,.btnoutline:hover {
    
    color: #000;
    background-color: white;
}
.btnoutline1:hover{
    color: #000;
    background-color: white;
}

.btn:hover{
    color: #fff;
}

.btn.small {
    border-width: 2px;
    padding: 0.25rem 1.5rem;
    font-size: 1rem;
}