@use "../scss/" as *;

.banner {
    box-sizing: border-box;
    height: 55vh;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include overlay();
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-image: linear-gradient(to top, $body-bg, rgba(black, 0));
    }
}

.detail{
    @include flex(flex-start, flex-start);
    max-width: 1400px;
    margin: -200px auto 0 auto;
    position: relative;
    padding: 0 2rem;
    gap: 2rem;
    @include mobile{
        margin: -260px auto 0 auto;
        flex-direction: column;
        
    }
    &__img{

        img{
            border-radius: 10px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            animation: animationA3 0.5s ease-in-out;
        }
    }
    &__info{
        h2{
            font-size: 3rem;
            font-weight: 700;
            animation: animationA2 0.3s ease-in;
            padding-top: 1rem;
        }
        &-overview{
            animation: animationA2 0.5s ease-in;
        }
        &-lang{
            animation: animationA2 0.6s ease-in;
        }
        &-date{
            animation: animationA2 0.7s ease-in;
        }
        &-genres{
            animation: animationA2 0.8s ease-in;
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
        }
        &-vote{
            animation: animationA2 1s ease-in;
        }

        p{
            text-align: justify;
        }
    
        
        &__info{
            img {
                transform: scale(1);
            }
        }
           
    }
    
}

.vote{
    color: red;
    font-weight: 600;
    font-size: 120%;
    padding-left: 2px;

}

.text-nice{
    text-align: justify;
}

.mb{
    margin-bottom: 0.5rem;
}

.mb-1{
    margin-bottom: 1rem;
}

.cast{
    padding: 2rem;
    max-width: 1400px;
    margin: auto;
}

.playwatch{
    padding-right: 10px;
    font-size: 90%;
}


.video{
    
    position: relative;
    padding: 17vh 1rem 0 1rem;
    
    &__watch{
        width: 100%;
        height: 70vh;
        border-radius: 15px;
        
    }
    &__info{
        padding: 2rem;
        max-width: 1660px;
        margin: auto;
        
        h2{
            margin-bottom: 1rem;
        }
        p{
            margin-bottom: 1rem;
        }
    }

}

.similar{
    padding: 0 2rem 2rem 2rem;
    max-width: 1660px;
    margin: auto;
}
.width-v{
    max-width: 1660px;
    padding-right: 0.4rem;
    margin: auto;
}
.season-number {
    display: inline-block;
    text-align: center;
    p{
        width: 40px;
        height: 40px;
        background-color: #fff;
        color: black;
        padding: 5px;
        border-radius: 10px;
        box-sizing: border-box;
       
    }
    

}