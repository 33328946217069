@use "../../scss/" as *;

.modal {
    @include flex(center, center);
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background-color: rgba(5, 5, 5, 0.616);
    opacity: 0;
    visibility: hidden;

    &.active {
        opacity: 1;
        visibility: visible;
    }

    &__content {
        padding: 1rem;
        border-radius: 3px;
        background-color: $body-bg;
        width: 45%;
        opacity: 0;
        transform: translateY(-250px);
        transition: transform 0.6s ease, opacity 0.6s ease;
        position: relative;

        @include table {
            width: 80%;
        }

        &__close {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 3rem;
            cursor: pointer;

            &:hover {
                color: #ff1e00;
            }
        }
    }

    &.active &__content {
        opacity: 1;
        transform: translateY(0);
    }
}